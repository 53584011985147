import {RTSTransformer} from './scripts/RTSTransformer.js';

const T = new RTSTransformer;
const ti = document.getElementById('txtInput');
const to = document.getElementById('txtOutput');
const Toast = document.getElementById('toast');
const btnChart = document.getElementById('link2Chart');
const btnHistory = document.querySelector('button#history');
const btnThreader = document.querySelector('button#threader');
const dialogHistory = document.getElementById('history-dialog');
const changesIndicator = document.getElementById('changes-indicator');
const historyEntryDisplayLength = 48;

let Store = 0;
try {
	Store = window.localStorage;
} catch(e) { console.info("localStorage is not available!"); }

btnHistory.onclick = showHistory;
btnChart.onclick = toggleCharChart;

// Modes: 0 - On demand; 1 - On the jump; 2 - On the fly
let $mode = 2; // default on-the-fly

function vTransform() {

	let output = T.convert(ti.value);

	// Delete the & in the output (kal&haara, bil&haNuDu)
	output = output.replace(/(ల్)(&)(\S)/gm, "$1$3");

	// Replace $$ with Rupee sign
	output = output.replace(/\${2}/g, '₹');

	to.value = output;
	ti.focus();

	// despatch event for plugins
	document.body.dispatchEvent(new Event('lekhini-transform'));

	// Auto-scroll
	if (ti.scrollHeight - ti.clientHeight == ti.scrollTop)
		to.scrollTop = to.scrollHeight - to.clientHeight;
	else 
		to.scrollTop = ti.scrollTop * 1.4;
}


// transform if these keys are pressed
function vVerify(evt){
	const $code = evt.keyCode;
	var $length = ti.value.length;

	// transfrom on demand (down arrow pressed), any mode
	if ($code == 40) vTransform();

	// on-the-jump mode
	else if ( $mode == 1 && (
		$code == 13	// Enter
		|| $code == 32	// Spacebar
		|| $code == 188	// Comma
		|| $code == 190	// Fullstop
		|| $code == 191	// Qestion mark
		|| $code == 49	// Exclamation mark
	)) vTransform();

	// disable on-the-fly mode if length > cut-off chars
	var $cutoff = 600;
	if ($length > $cutoff) {
		if ($mode == 2) $mode = 1; //set to on-the-jump
	} else {
		// on-the-fly mode
		if ($mode == 2) vTransform();

		// switch to on-the-fly mode, if length is zero
		if (!$length && $mode != 0) $mode = 2;
	}
}

function vSelectIt(){
	to.select();
	to.focus();
	if (navigator.clipboard) navigator.clipboard.writeText(to.value).then(() => showToast());
}

function showToast(text) {
	Toast.style.display = 'none';
	if (text) Toast.textContent = text;
	Toast.style.display = 'block';
}

function vHideCharChart(){
	document.getElementById("sidebar").style.display = "none";
	btnChart.classList.remove('active');
	if (Store) Store.setItem('helpChart', 0);
}
function vShowCharChart(){
	document.getElementById("sidebar").style.display = "block";
	btnChart.classList.add('active');
	if (Store) Store.setItem('helpChart', 1);
}

function toggleCharChart() {
	document.getElementById('sidebar').style.display == 'block' ? vHideCharChart() : vShowCharChart();
}
function reuseLastId() {

	if (!Store) return;
		
	var lastId = Store.getItem('lastId');
	var lastIdContent = JSON.parse(Store.getItem(lastId))?.text;
	if (
		lastIdContent && (
			// 1) if text is insignificant
			lastIdContent.length < 49
			// 2) if input area is autofilled by the browser
				|| ti.value.startsWith(lastIdContent)
		)
	)
		ti.dataset.id = lastId;
}

function vInitialize(){

	// TODO: Remove this block in next version
	try {
		// expire helpChart cookie, as we are now using localstorage
		document.cookie = 'lekhinihelpchart=1; expires=Sat, 01 Jan 2000 00:00:00 GMT';
	}
	
	if (
		(Store && !+Store.getItem('helpChart'))
			|| window.innerWidth <= 600
	) vHideCharChart();
	else vShowCharChart();

	ti.focus();
	ti.dataset.changes = '';
	ti.dataset.id = Date.now();

	// set threader to false by default
	if (Store && !Store.getItem('threader')) Store.setItem('threader', 0);

	// see and use last id for history
	reuseLastId();

	// if threader is turned on
	if (Store && +Store.getItem('threader')) {
		import('./plugins/twillekhini/index.js')
			.then(tth => {
				tth.activateThreader();
				btnThreader.classList.add('active');
			})
		;
	}
	// notifiy plugins
	document.body.dispatchEvent(new Event('lekhini-initialize'));

}

ti.addEventListener('keydown', handleKeyDown);
ti.addEventListener('beforeinput', handleBeforeInput);
ti.addEventListener('input', handleInput);
ti.onkeyup = vVerify;

function handleKeyDown(evt) {
	if (evt.keyCode !== 9 /* tab */) return;

	vSelectIt();
	evt.preventDefault();
}

function handleBeforeInput() {
	// capture length before input
	this.dataset.length = this.value.length;
}

function handleInput(evt) {

	/**
	   Fix for duplicate entries: Upon restart, browser is triggering
	   input event after it restores text in text area. This event has
	   blank inputType.

	   And such text is not available by the time vInitialize() run.
	   Let's fix that here. And, stop further processing.
	*/
	if (!evt.inputType) {
		reuseLastId();
		return;
	}

	if (this.value.length > 0) {

		this.dataset.changes = 1;
		if (Store) changesIndicator.classList.add('has-changes');

		// generate new ID, if overwriting current text
		if (this.dataset.length > 48 && this.value.length == 1)
			this.dataset.id = Date.now();

		return;
	}

	// no text in the textarea

	this.dataset.changes = '';
	changesIndicator.classList.remove('has-changes');

	// fresh start on full delete
	if (Store && (
		evt.inputType == 'deleteContentForward'
		|| evt.inputType == 'deleteContentBackward'
		|| evt.inputType == 'deleteByCut'
	)) {

		let stored = JSON.parse(Store.getItem(this.dataset.id));
		// generate new ID, if current ID has text above some chars
		if (stored?.text.length > 48) this.dataset.id = Date.now();
	}
}



// auto-save
if (Store) window.setInterval(save, 10000);

function save() {
	if (!Store) return;
	if (!ti.dataset.changes) return;

	let str = JSON.stringify({
		updated: Date.now()
		, text: ti.value
		, display: to.value.substring(
			0
			, to.value.length < historyEntryDisplayLength
				? to.value.length
				: to.value.indexOf(' ', historyEntryDisplayLength)
		) + '…'
	});
	Store.setItem(ti.dataset.id, str);
	Store.setItem('lastId', ti.dataset.id);
	ti.dataset.changes = '';
	changesIndicator.classList.remove('has-changes');
}


function showHistory(evt) {

	const $d = document.getElementById('history-dialog');
	$d.style.visibility = 'visible';
	const $c = $d.querySelector('.modal-content');
	$c.textContent = '';

	if (!Store) {
		const $m = document.createElement('div');
		$m.classList.add('inline-message');
		const $p = document.createElement('p');
		$p.innerHTML = 'మీ విహారిణి స్థానికనిల్వను (localStorage) వాడుకునే అనుమతి లేఖినికి లేదు. కుకీలను నిరోధిస్తే లేఖిని స్థానికనిల్వను వాడుకోలేదు. మీ రచనలను భద్రపరచడానికి, లేఖినికి కుకీల/ఆఫ్‌లైన్ నిల్వ అనుమతిని ఇవ్వండి.';
		$m.append('⚠ అనుమతి లోపం!', $p);
		$c.appendChild($m);
		return;
	}

	let keys = Object.keys(Store);
	keys = keys.filter(k => !['threader', 'lastId', 'helpChart'].includes(k));

	if (!keys.length) {
		const $m = document.createElement('div');
		$m.classList.add('inline-message');
		const $p = document.createElement('p');
		$p.innerHTML = 'లేఖినిలో మీరు టైపు చేసేది మీ పరికరంలోనే భద్రమవుతుంది. అలా భద్రమైనవి ఇక్కడ కనిపిస్తాయి.<br><br>గమనిక: ఈ చరిత్రను మీ విహారిణి కొన్నిరోజుల తర్వాత (లేదా స్థలాభావం వల్ల) తుడిచివేయవచ్చు. కనుక, <b>దీర్ఘకాలంపాటు మీ రచనలను భద్రపరచుకోడానికి లేఖిని చరిత్ర మీద ఆధారపడవద్దు</b>.';
		$m.append('ఇంకా ఏమీలేవు!', $p);
		$c.appendChild($m);
		return;
	}

	keys
		.map(function(key) { return { 'key': key, value: JSON.parse(Store.getItem(key))};})
		.sort(function(a, b) { return b.value.updated - a.value.updated})
		.slice(0, 16) // show top entries
		.forEach(addEntry)
	;

	function addEntry(e) {
		const content = e.value;
		
		const $e = document.querySelector('template').content.firstElementChild.cloneNode(true);
		$e.dataset.id = e.key;
		$e.onclick = loadHistoryEntry;
		$e.querySelector('.history-star').onclick = toggleHistoryEntryStar;

		if (e.key.startsWith('*')) $e.classList.add('starred');

		$e.querySelector('.history-display').textContent = content.display;
		$e.querySelector('.history-last-updated')
		  .textContent = (new Intl.DateTimeFormat('te', {dateStyle: 'long', timeStyle: 'medium'}))
			  .format(new Date(content.updated));

		//console.info(ti.dataset.id, e.key);
		if (ti.dataset.id == e.key) $e.classList.add('active');

		$c.appendChild($e);
	}
}


function toggleHistoryEntryStar(evt) {

	const entry = this.parentNode;
	const oldId = entry.dataset.id;
	const newId = oldId.startsWith('*')
		  ? oldId.replace('*', '')
		  : '*' + oldId
	;

	if (entry.classList.contains('active') && ti.dataset.id == oldId)
		ti.dataset.id = newId;

	if (Store.getItem('lastId') == '' + oldId) Store.setItem('lastId', newId);

	entry.classList.toggle('starred');
	Store.setItem(newId, Store.getItem(oldId));
	Store.removeItem(oldId);
	evt.stopPropagation();
}

function loadHistoryEntry(evt) {

	ti.dataset.id = this.dataset.id;
	this.classList.add('active');
	ti.value = JSON.parse(Store.getItem(this.dataset.id)).text;
	Store.setItem('lastId', this.dataset.id);
	vTransform();

	dialogHistory.style.visibility = 'hidden';
}

document.onkeyup = handleDocKeyUp;
document.body.onload = vInitialize;

function handleDocKeyUp(evt) {
	if (evt.keyCode == 27 || evt.key == 'Escape') {
		document.querySelectorAll('.modal-container').forEach(n => {
			n.style.visibility = 'hidden';
		});
	}
}


function closeModal() {
	this.parentNode.parentNode.parentNode.style.visibility = 'hidden';
}

document.querySelectorAll('.modal-close').forEach(c => {
	c.onclick = closeModal;
})


/**
 * Service Worker
 */
navigator.serviceWorker.register(
	// Parcel recommended way. See: https://parceljs.org/languages/javascript/#service-workers
	new URL('sw.js', import.meta.url)
	, {type: 'module'}
);


/**
 * modules
 */
document.querySelector('button#shotter').onclick = function () {
	import('./plugins/snapper/index.js')
		.then(s => s.render())
};

btnThreader.addEventListener('click', function() {
	import('./plugins/twillekhini/index.js')
		.then(tth => tth.toggleThreader())
	;
});
/*
btnThreader.onclick = function() {
	import('./plugins/twillekhini/index.js')
		.then(tth => tth.toggleThreader())
	;
};
*/


/**
 * Clean-up history
 */
window.requestIdleCallback(cleanOldItems, {});

function cleanOldItems() {

	const threshold = Date.now() - 60*24*60*60*1000;
	Object.keys(Store)
		.forEach(key => {
			// pass non-history keys and "pinned" entries
			if  (/^\D+$/.test(key)) return;

			// pass non-old keys
			if (+key >= threshold) return;

			// delete older ones, only if there are no updates recently
			if (JSON.parse(Store.getItem(key)).updated < threshold)
				Store.removeItem(key);
		});
}
