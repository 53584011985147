// $Id: RTSTransformer.js,v 1.2 2005/09/25 14:52:28 vnagarjuna Exp $ -->

// Copyright 2005 Nagarjuna Venna <vnagarjuna@yahoo.com>
// See http://lekhini.org/scripts/padma-license.txt

// Changes Copyright 2006 Veeven <veeven at gmail dot com>
// See http://lekhini.org/license.txt

import {RTSParser} from './RTSParser.js';
import {Unicode} from './Unicode.js';

export function RTSTransformer() {

	this.input = 0; // rtsEnglish
	this.output = 1; // rtsTelugu
	this.rtsMode = RTSTransformer.rtsEnglish;
	this.outputWriter = new Unicode;

	this.toOutputFormat = function (syllable) {
		return this.outputWriter.transformFromPadma(syllable);
	}

	this.convert = function (text) {

		var parts = text.split(/`|##/); // backtick or ##
		var output = '';
		
		for (var i = 0; i < parts.length; i++) {
			// even pieces: convert
			if (i % 2 == 0) output += this.parse(parts[i]);
			// odd pieces: as is
			else output += parts[i];
		}
		
		return output;
	}

	this.parse = function (text) {
		var parser = new RTSParser(text);
		var output = "";
		
		while (parser.more())
			output += this.toOutputFormat(parser.next());
		
		return output;
	}

	//return this;
}
